.menu {
  @apply hidden md:flex flex-row justify-between;
}

.menu li {
  @apply mx-5;
}

.btn-primary {
  @apply bg-primary-200 hover:bg-primary-400 rounded-xl text-white px-6 py-3;
}

.footer {
  @apply max-w-7xl md:mx-auto  bg-primary-400 px-5 pt-10 md:m-10 md:p-20 rounded-none md:rounded-3xl  text-white;
}

.footer-links li {
  @apply text-white text-p hover:text-violet-200 my-2  font-light cursor-pointer;
}

.footer-header {
  @apply text-h5;
}

.footer-links {
  @apply mb-7 md:mb-1;
}
.gradient-text {
  @apply text-transparent bg-clip-text bg-gradient-to-r from-primary-400 to-error-200;
}

.nav-item {
  @apply cursor-pointer hover:text-secondary-100;
}
.nav-item-mobile {
  @apply cursor-pointer hover:text-secondary-100 mb-5 text-h5;
}
