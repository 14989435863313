/* font styles Brinnan */
/* new font styles Raleway */

@font-face {
  font-family: "Raleway";
  src: url("./assets/fonts/Raleway-Regular.ttf");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway";
  src: url("./assets/fonts/Raleway-Regular.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway";
  src: url("./assets/fonts/Raleway-Bold.ttf");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway";
  src: url("./assets/fonts/Raleway-Black.ttf");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

html {
  scroll-behavior: smooth;
}

/* remove outline from all inputs  */
textarea:focus,
input:focus {
  outline: none;
}

/* remove arrows from number input*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body {
  background-color: #f4f4f4;
}

/* tailwind css inclusion */
@tailwind base;
@tailwind components;
@tailwind utilities;

h1 {
  @apply text-5xl font-bold;
}

.btn-primary-full {
  @apply text-white bg-primary-200 text-sm rounded-lg px-5 py-2;
}

.all-inputs {
  @apply py-3 px-8 rounded-xl bg-primary-100 text-primary-200 border-0;
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.tab-active {
  @apply inline-flex font-semibold items-center gap-3 p-4 text-secondary-200 border-b-2 border-secondary-200 focus:outline-none;
}

.tab-inactive {
  @apply inline-flex items-center gap-3 p-4 text-gray-400 border-b-2 border-none;
}
